import React from "react";
import { graphql, Link } from "gatsby";
import { Container, Button } from "theme-ui";
import Layout from "../components/layout";


export default function BlogPost({ data }) {
  const post = data.markdownRemark;
  return (
    <Layout showBackButton>
      <h1>{post.frontmatter.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
